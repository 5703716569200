/**
 * Coralogix logging module
 */

// Import necessary modules from Coralogix
import { CoralogixRum } from '@coralogix/browser';
import { v4 as uuidV4 } from 'uuid';
import _ from 'lodash';
import ClientJs from 'clientjs';
import { modifyAndObfuscateLog } from 'logger-customization';
// eslint-disable-next-line import/no-cycle
import { getAuth0Id } from '../auth/helpers';

const clientJs = new ClientJs();

const CoralogixLogSeverity = {
  Trace: 2,
  Debug: 1,
  Info: 3,
  Warn: 4,
  Error: 5,
  Fatal: 6
};

const SESSION_ID_KEY = 'session_id';
let sessionId = window.sessionStorage.getItem(SESSION_ID_KEY);

export const getSessionId = () => {
  if (_.isEmpty(sessionId)) {
    sessionId = uuidV4();
    window.sessionStorage.setItem(SESSION_ID_KEY, sessionId);
  }
  return sessionId;
};

const deviceMetadata = {
  softwareVersion: process.env.REACT_APP_BUILD_VER,
  browser: clientJs.getBrowser(),
  os: clientJs.getOS(),
  userAgent: clientJs.getUA()
};

const addMetaData = (msg, source, data) => {
  const updatedData = {
    msg,
    source,
    deviceMetadata,
    schemaVersion: 'v2',
    sessionId: getSessionId(),
    customFields: {}
  };

  const auth0Id = getAuth0Id();
  if (auth0Id) {
    updatedData.userMetadata = { auth0Id };
  }

  if (typeof data === 'string' || data instanceof String) {
    updatedData.logMetadata = { data };
  } else if (
    data &&
    !(Object.keys(data).length === 0 && data.constructor === Object)
  ) {
    updatedData.logMetadata = modifyAndObfuscateLog(data);
  }
  return updatedData;
};

class LoggingModule {
  static log(level, msg, source, data) {
    const metadata = addMetaData(msg, source, data);

    CoralogixRum.log(level, msg, metadata);
  }

  static trace(msg, source, data) {
    this.log(CoralogixLogSeverity.Trace, msg, source, data);
  }

  static info(msg, source, data) {
    this.log(CoralogixLogSeverity.Info, msg, source, data);
  }

  static warn(msg, source, data) {
    this.log(CoralogixLogSeverity.Warn, msg, source, data);
  }

  static error(msg, source, data) {
    this.log(CoralogixLogSeverity.Error, msg, source, data);
  }

  static fatal(msg, source, data) {
    this.log(CoralogixLogSeverity.Fatal, msg, source, data);
  }

  static debug(msg, source, data) {
    this.log(CoralogixLogSeverity.Debug, msg, source, data);
  }
}

export default LoggingModule;
